import React from "react"
import Dan from "../images/headshot-dan.jpg"
import Stacey from "../images/headshot-stacey.jpg"
import Layout from "../components/layout"
import "./who.styl"

const WhoPage = () => (
  <Layout>
    <h1>Who Are We</h1>
    <section className="who">
      <article>
        <img height={75} src={Dan} />
        <div>
          <div className="name">Dan Hostick</div>
          <div className="title">Co-Founder</div>
          <p>
            After 45 years coaching entrepreneurs, I’m excited to be involved in this endeavour because I see the
            possibility of reaching and helping a broader range of people, and to translate my library of materials for
            digital delivery.
          </p>
        </div>
      </article>

      <article>
        <img src={Stacey} />
        <div>
          <div className="name">Stacey Vetzal</div>
          <div className="title">Co-Founder</div>
          <p>
            As a coach, and having built software development organizations in the past, I’m excited to both apply my
            coaching skills with a fresh team of developers, and to explore this opportunity to change the way people
            coach
            others using technology.
          </p>
        </div>
      </article>
    </section>
  </Layout>
)

export default WhoPage
